import React, { useState } from 'react';
import './involved.css';

const GetInvolvedPage = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="container">
      <h1 className="heading">Get Involved</h1>
      <div className="content">
        <p className="text">
          Are you interested in making a difference? Join us as a volunteer and help us in our mission to empower the youth through technology. Please reach out to us via email at <strong>codepulsenetwork@gmail.com</strong> to learn more about how you can get involved. If possible, please include your resume and some information about yourself and what interests you about our organization in the email.
        </p>
        <a
          href="mailto:codepulsenetwork@gmail.com"
          className={`button ${isHovered ? 'buttonHover' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Get Involved Today
        </a>
      </div>
    </div>
  );
};

export default GetInvolvedPage;
