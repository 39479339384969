import React from 'react';
import './footer.css'; // Uncomment the CSS import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className='footer-container-custom'>
      <section className='social-media-custom'>
        <div className='social-media-wrap-custom'>
          <div className='social-icons-custom'>
            <a
              className='social-icon-link-custom instagram'
              href='https://www.instagram.com/codepulse.network/'
              target='_blank'
              aria-label='Instagram'
              rel='noopener noreferrer'
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              className='social-icon-link-custom linkedin'
              href='https://www.linkedin.com/company/codepulse-network/'
              target='_blank'
              aria-label='LinkedIn'
              rel='noopener noreferrer'
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className='footer-logo-custom'>
            <a href='/' className='social-logo-custom'>
              <img src='/logo.png' alt='Logo' className='logo-custom' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;