import React from 'react';
import '../../App.css';

export default function About() {
  return (
    <div className="about">
      <h1>About Us</h1>
    </div>
  );
}
