import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './initiatives.css';

const EventSection = () => {
  const events = [
    {
      title: "Introductory Class (Free of Charge and Beginner Friendly)",
      date: "One Day (2-3 hours)",
      description: `
        <p>This class is perfect for a group of students in a school setting, and is also open to libraries, recreation centers, and other similar venues.</p>
        <p>CodePulse Network offers engaging coding classes directly to students, completely free of charge and tailored for beginners. Our introductory classes are designed to get students excited and engaged with coding.</p>
        <p>No prior coding experience required.</p>
        <p>Curriculum tailored to middle and high school students (Grades 6-12), providing a solid foundation for future learning.</p>
        <p>Inspires students to envision themselves as future software engineers.</p>
      `,
      imageUrl: "/hero.png",
      category: "Coding",
      link: "https://booking.codepulse.ca/classes/66a97fe7867585725aa46492"
    },
    {
      title: "CodePulse Network Coding Fundamentals (Free of Charge and Beginner Friendly)",
      date: "Three Sessions (2 hours each)",
      description: `
        <p>This class is ideal for a group of students in a school setting, and is also open to libraries, recreation centers, and other similar venues.</p>
        <p>Our advanced coding class takes students through a deeper understanding of coding basics over three sessions. Designed for students in Grades 8-12, this class provides a strong foundation in coding, equipping students with the necessary skills to further explore and excel in computer science.</p>
        <p>Session 1: Introduction to Advanced Concepts and Tools.</p>
        <p>Session 2: Hands-on Coding Instruction and Teaching.</p>
        <p>Session 3: Applying Coding Skills in Real-world Projects.</p>
        <p>By the end of the course, students will have a solid grasp of advanced coding techniques and will be well-prepared to tackle more complex challenges.</p>
      `,
      imageUrl: "/fundemental.png",
      category: "Coding",
      link: "https://booking.codepulse.ca/classes/66a99323252c1fc59791a749"
    }
  ];

  return (
    <div className="main-content">
      <h1 className="header">Initiatives</h1>
      <div className="event-section">
        {events.map((event, index) => (
          <a href={event.link} target="_blank" rel="noopener noreferrer" className="event-link" key={index}>
            <div className="event">
              <div className="event-content">
                <img src={event.imageUrl} alt={event.title} className="event-image" />
                <div className="event-details">
                  <h4 className="event-category">{event.category}</h4>
                  <h2 className="event-title">{event.title}</h2>
                  <p className="event-date">{event.date}</p>
                  <div className="event-description" dangerouslySetInnerHTML={{ __html: event.description }}></div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default EventSection;