// src/components/ContactIcon.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

const ContactIcon = () => {
    const navigate = useNavigate();

    const iconStyle = {
        position: 'fixed',
        bottom: '30px', // Adjust this value to move the icon up or down
        right: '20px',  // Adjust this value to move the icon left or right
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '50%',
        padding: '10px',
        zIndex: 1000,
        color: '#fff',
        fontSize: '40px',
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'centeter',
        cursor: 'pointer'
    };

    const hoverStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    };

    const [isHovered, setIsHovered] = React.useState(false);

    const handleClick = () => {
        navigate('/contact-us', { replace: true });
        window.scrollTo(0, 0);
    };

    return (
        <div 
            style={isHovered ? { ...iconStyle, ...hoverStyle } : iconStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={faComment} />
        </div>
    );
};

export default ContactIcon;
