import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ThankYou() {
  const navigate = useNavigate(); // Hook to navigate programmatically

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 2000); // Redirect after 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#fff',  /* White background */
      color: '#000000',  /* Black text color */
      fontSize: '2rem'  /* Adjust font size */
    }}>
      <h1>We Have Received Your Donation Request</h1>
      <p>Thank you for your generosity. We will email you within two business days with further instructions.</p>
    </div>
  );
}
