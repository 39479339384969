import React from 'react';
import './App.css';
import Navbar from './Components/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/home';
import About from './Components/Pages/About';
import Contact from './Components/Pages/contact';
import Donate from './Components/Pages/donate';
import Initiatives from './Components/Pages/initiatives'; // Correct spelling
import Involved from './Components/Pages/involved';
import Signup from './Components/Pages/signup';
import Footer from './Components/footer';
import ThankYou from './Components/Pages/Thanks'; // Keep the original name
import ThankYou1 from './Components/Pages/thanks1'; // Keep the original name
import OurMission from './Components/Pages/ourmisson'; // Correct naming convention
import OurTeam from './Components/Pages/ourteam'; // Correct naming convention
import ContactIcon from './Components/contacticon';

function App() {
  return (
    <Router>
      <div className="App">    
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/initiatives' element={<Initiatives />} /> {/* Correct spelling */}
          <Route path='/get-involved' element={<Involved />} />
          <Route path='/sign-up' element={<Signup />} />
          <Route path='/thank-you' element={<ThankYou />} /> {/* ThankYou route */}
          <Route path='/thankyou' element={<ThankYou1 />} /> {/* ThankYou1 route */}
          <Route path='/our-mission' element={<OurMission />} /> {/* OurMission route */}
          <Route path='/our-team' element={<OurTeam />} /> {/* OurTeam route */}
        </Routes>
        <Footer /> {/* Move Footer inside Router */}
        <ContactIcon /> {/* Add the ContactIcon component */}
      </div>
    </Router>
  );
}

export default App;
