import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import { Button } from './button';

function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => {
        setClick(false);
        setDropdown(false);
    };
    const [button, setButton] = useState(true);

    useEffect(() => {
        const showButton = () => {
            if (window.innerWidth <= 960) {
                setButton(false);
            } else {
                setButton(true);
            }
        };

        showButton();
        window.addEventListener('resize', showButton);
        return () => window.removeEventListener('resize', showButton);
    }, []);

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        if (dropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdown]);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    <img src='/logo.png' alt='Logo' className='logo' />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className={`nav-item ${dropdown ? 'active' : ''}`} ref={dropdownRef}>
                        <div className="nav-links" onClick={toggleDropdown}>
                            About Us <i className="fas fa-caret-down" style={{ marginLeft: '0.5em' }}></i>
                        </div>
                        {dropdown && (
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to='/our-mission' className="dropdown-link" onClick={closeMobileMenu}>
                                        Our Mission
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/our-team' className="dropdown-link" onClick={closeMobileMenu}>
                                        Our Team
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li className='nav-item'>
                        <Link to='/initiatives' className="nav-links" onClick={closeMobileMenu}>
                            Initiatives
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/get-involved' className="nav-links" onClick={closeMobileMenu}>
                            Get Involved
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/donate' className="nav-links" onClick={closeMobileMenu}>
                            Donate
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact-us' className="nav-links" onClick={closeMobileMenu}>
                            Contact Us
                        </Link>
                    </li>
                    {!button && (
                        <li className='nav-item'>
                            <a href="https://booking.codepulse.ca/" className="nav-links" onClick={closeMobileMenu} target="_blank" rel="noopener noreferrer">
                                Sign Up
                            </a>
                        </li>
                    )}
                </ul>
                {button && (
                    <Button
                        link="https://booking.codepulse.ca/"
                        buttonStyle='btn--outline'
                        padding='1.2vh 6.5vh'
                        fontSize='2.2vh'
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sign Up
                    </Button>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
