import React from 'react';
import { Link } from 'react-router-dom';
import './ourmisson.css'; // Assuming you have a CSS file for styling

const OurMission = () => {
  return (
    <div className="our-mission-main-content">
      <div className="our-mission">
        <div className="our-mission-title">Our Mission</div>
        <section className="our-mission-content">
          <h2>Empowering Future Innovators</h2>
          <p>
            CodePulse Network is a non-profit organization committed to empowering the next generation of software engineers by sparking interest in coding among middle schoolers and high school students. Our tailored curriculum offers introductory classes designed to inspire curiosity, foster creativity, and cultivate problem-solving skills in young minds. Join us in unlocking the power of code and shaping a brighter, more inclusive future in technology.
          </p>
          <h2>Core Values</h2>
          <ul>
            <li>
              <strong>Innovation:</strong> We believe in the power of creativity and innovation to drive progress and solve complex problems.
            </li>
            <li>
              <strong>Inclusivity:</strong> We strive to create an inclusive environment where everyone feels welcome and valued.
            </li>
            <li>
              <strong>Excellence:</strong> We are committed to excellence in all our programs and initiatives, ensuring high-quality education and support.
            </li>
            <li>
              <strong>Collaboration:</strong> We promote teamwork and collaboration, recognizing that great ideas come from diverse perspectives.
            </li>
          </ul>
          <h2>Our Vision</h2>
          <p>
            Our vision is to create a world where every young person has the opportunity to learn coding and technology skills, regardless of their background or circumstances. By providing accessible and engaging educational experiences, we aim to inspire the next generation of tech leaders and innovators who will drive positive change in their communities and beyond.
          </p>
          <h2>Programs and Initiatives</h2>
          <ul>
            <li>
              <strong>Introductory Coding Classes:</strong> Our classes introduce students to the basics of coding, helping them develop foundational skills in a supportive and engaging environment.
            </li>
            <li>
              <strong>Community Outreach:</strong> We actively engage with local communities to promote the importance of coding education and provide resources to underserved schools and organizations.
            </li>
          </ul>
          <h2>Get Involved</h2>
          <p>
            We invite you to join us in our mission to empower the next generation of software engineers. Whether you are a student, parent, educator, or industry professional, there are many ways to get involved and make a difference. Together, we can unlock the potential of young minds and create a brighter future through technology.
          </p>
          <p>
            <Link to="/get-involved" onClick={() => window.scrollTo(0, 0)}>Learn more about how to get involved</Link>
          </p>
        </section>
      </div>
    </div>
  );
};

export default OurMission;