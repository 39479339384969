import React from 'react';
import './ourteam.css';

const teamMembers = [
  {
    name: 'Aarib Sarker',
    title: 'President and Founder',
    description: `Aarib Sarker is the President and Founder of CodePulse Network, a pioneering nonprofit dedicated to educating the next generation of software engineers. As an International Baccalaureate (IB) student, Aarib balances rigorous academic challenges with his passion for teaching. He has developed and implemented a comprehensive coding curriculum for high school and middle school students. His dedication to computer science is further demonstrated through his role as a Machine Learning Intern at Revyl, where he continues to expand his programming skills. Aarib's commitment to education and technology drives the mission of CodePulse Network, inspiring and empowering young minds to excel in STEM fields.`,
    imageUrl: '/Aarib.jpeg',
    email: 'aaribsarker@gmail.com' // Optional email field
  },
  {
    name: 'Anam Hira',
    title: 'Board Member',
    description: `Anam Hira is a valued board member of CodePulse Network, bringing extensive expertise in machine learning and software engineering. As a Co-Founder of Revyl, he addresses critical software testing issues, enhancing product reliability. His experience as a Machine Learning Engineer and Software Engineer Intern at Uber highlights his proficiency in advanced technologies. Anam's background in Computer Science and Statistics from the University of British Columbia, combined with his industry experience, makes him an exceptional mentor, providing students with invaluable knowledge and inspiration.`,
    imageUrl: '/anam.jpeg',
    email: '' // Optional email field
  },
    {
      name: 'Tej Pooni',
      title: 'Board Member',
      description: `Tej Pooni is a dedicated board member of CodePulse Network, bringing valuable experience in software development and engineering. As a Software Development Engineer Intern at Amazon and a Computer Science student at Simon Fraser University, Tej applies his strong technical skills to solve complex problems. His previous roles as a Software Developer at Damon Motorcycles and a Software Engineer at FORM highlight his proficiency in programming and software engineering. Tej's commitment to education and technology provides invaluable mentorship to our students, helping them excel in STEM fields and preparing them for future success.`,
      imageUrl: '/TEJ.jpeg',
      email: '' // Optional email field
    },
    {
      name: 'Wasif Sarker',
      title: 'Board Member',
      description: `Wasif Sarker is a dedicated board member of CodePulse Network, bringing extensive experience in software engineering and development. As a Full Stack Engineer at Revyl.AI, Wasif has demonstrated his proficiency in creating robust and scalable software solutions. His academic background in Mechanical Engineering at the University of British Columbia complements his technical skills, providing a well-rounded approach to problem-solving. Wasif's experience with UBC Rover and FortisBC has further refined his abilities in project management and system design. His commitment to education and technology, along with his real-world software engineering expertise, makes him an invaluable mentor, guiding students to excel in STEM fields and preparing them for successful careers.`,
      imageUrl: '/Wasif.jpg',
      email: '' // Optional email field
    },
  {
    name: 'Rohan Jassi',
    title: 'Regional Director of Educational Programs',
    description: `Rohan Jassi is a dedicated student in the International Baccalaureate (IB) program, recognized for his academic excellence and commitment to holistic education. With a strong foundation in coding, Rohan brings a unique blend of analytical and creative problem-solving skills to the table. His experience spans various programming languages and projects, showcasing his ability to adapt and innovate in the ever-evolving tech landscape. As the Regional Director of Educational Programs, Rohan is driven by a passion for making a positive impact and is actively striving to support and enhance this organization, leveraging his technical expertise and collaborative spirit to drive meaningful change and growth.`,
    imageUrl: '/rohan.jpg',
    email: 'rj2905077@gmail.com' // Optional email field
  },
  {
    name: 'Aazmain Aarib',
    title: 'Tri-Cities Director of Educational Programs',
    description: `Aazmain Aarib is a senior in high school and serves as the Tri-Cities Director of Educational Programs at CodePulse Network. He is highly skilled in Python and is known for his dedication and proficiency in programming. Aazmain's commitment to education and his passion for technology make him an exceptional leader and mentor. His enthusiasm for teaching and his ability to connect with students inspire the next generation of tech innovators, helping them to excel in their coding journeys and achieve their academic and career goals.`,
    imageUrl: 'aarib2.jpg',
    email: '' // Optional email field
  },
  {
    name: 'Adriel Dela Pena',
    title: 'Head Instructor',
    description: `Adriel Dela Pena is the Head Instructor at CodePulse Network, bringing extensive knowledge and experience in web development. Proficient in HTML, CSS, and JavaScript, Adriel has taken numerous classes in web development, equipping him with the skills to teach and inspire students. His dedication to education and his expertise in web technologies make him an invaluable asset to our team, guiding students to excel in their coding journeys and preparing them for successful careers in technology.`,
    imageUrl: 'dela.jpg',
    email: '' // Optional email field
  }
];

const OurTeam = () => {
  return (
    <div className="our-team main-content">
      <header className="header">
        <h1>Our Team</h1>
      </header>
      <section className="team-content">
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              {member.imageUrl && <img src={member.imageUrl} alt={member.name} />} {/* Added image element */}
              <h3>{member.name}</h3>
              <h4>{member.title}</h4>
              <p>{member.description}</p>
              {member.email && <p>Email: <a href={`mailto:${member.email}`}>{member.email}</a></p>}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default OurTeam;
