import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BookingForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: '',
        time: '',
        service: '',
        grade: '',
        specialRequests: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDateChange = (e) => {
        const { value } = e.target;
        const date = new Date(value);
        const day = date.getUTCDay();

        // Check if the selected date is a weekday (Monday to Friday)
        if (day === 0 || day === 6) {
            alert('Please select a weekday.');
            e.target.value = '';
        } else {
            handleChange(e);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        formDataToSend.append("access_key", process.env.REACT_APP_ACCESS_KEY);
        formDataToSend.append("name", formData.name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("date", formData.date);
        formDataToSend.append("time", formData.time);
        formDataToSend.append("service", formData.service);
        formDataToSend.append("grade", formData.grade);
        formDataToSend.append("specialRequests", formData.specialRequests);

        const object = Object.fromEntries(formDataToSend);
        const json = JSON.stringify(object);

        try {
            const res = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: json
            });

            const data = await res.json();

            if (data.success) {
                console.log("Success", data);
                navigate("/thank-you");
            } else {
                console.error("Error", data);
            }
        } catch (error) {
            console.error("Fetch error: ", error);
        }
    };

    const timeOptions = [];
    for (let hour = 8; hour <= 16; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            timeOptions.push(time);
        }
    }
    timeOptions.push('17:00'); // Adding 5:00 PM separately

    const gradeOptions = ["Mixed Grades"];
    for (let grade = 5; grade <= 12; grade++) {
        gradeOptions.push(`Grade ${grade}`);
    }

    return (
        <div className="booking-container">
            <h1 className="booking-heading">Booking Form</h1>
            <div className="booking-form">
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleDateChange}
                        required
                    />
                    <select
                        id="time"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a time</option>
                        {timeOptions.map((time) => (
                            <option key={time} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                    <select
                        id="service"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a service</option>
                        <option value="Introductory Coding Class">Introductory Coding Class</option>
                    </select>
                    <select
                        id="grade"
                        name="grade"
                        value={formData.grade}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a grade</option>
                        {gradeOptions.map((grade) => (
                            <option key={grade} value={grade}>
                                {grade}
                            </option>
                        ))}
                    </select>
                    <textarea
                        id="specialRequests"
                        name="specialRequests"
                        placeholder="Special Requests"
                        value={formData.specialRequests}
                        onChange={handleChange}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default BookingForm;