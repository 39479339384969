import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../App.css';
import './donate.css'

export default function Contact() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const API_URL = `${process.env.REACT_APP_API_URL}/contact`;

  useEffect(() => {
    let timer;
    if (isSubmitting) {
      timer = setTimeout(() => {
        navigate('/thank-you');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isSubmitting, navigate]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError(null);
    const formData = new FormData(event.target);
    const object = Object.fromEntries(formData);

    try {
      const res = await axios.post(API_URL, object, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (res.status === 200) {
        console.log('Success', res.data);
        navigate('/thank-you');
      } else {
        throw new Error(res.data.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Axios error: ', error);
      setError(error.message || 'Network error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="main-content">
      <div className="contact-container">
        <h1 className="header1">Contact Us</h1>
        <div className="contact-form">
          <form onSubmit={onSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required disabled={isSubmitting} />
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required disabled={isSubmitting} />
            </div>
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" placeholder="Message" disabled={isSubmitting}></textarea>
            <button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit Form'}</button>
          </form>
          {error && <p className="error">{error}</p>}
        </div>
        <div className="contact-info">
          <p>Email: <a href="mailto:codepulsenetwork@gmail.com">codepulsenetwork@gmail.com</a></p>
        </div>
      </div>
    </div>
  );
}