 import React from 'react'
 import '../../App.css'
 import Hero from '../hero'

 const Home = () => {
    return (
        <>
            <Hero/> 
        </>
    )
 }

 export default Home
   