import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export default function ThankYou() {

  const navigate = useNavigate(); // Hook to navigate programmatically


  useEffect(() => {

    const timer = setTimeout(() => {

      navigate('/');

    }, 1000); // Redirect after 1 second


    return () => clearTimeout(timer); // Cleanup the timer on component unmount

  }, [navigate]);


  return (

    <div style={{

      display: 'flex',

      flexDirection: 'column',

      alignItems: 'center',
      justifyContent: 'center',

      height: '100vh',
      backgroundColor: '#fff',  /* White background */

      color: '#000000',  /* Black text color */

      fontSize: '4.4rem'  /* Adjust font size */

    }}>

      <h1>Thank You for Submitting!</h1>

    </div>

  );
}
