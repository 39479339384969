import React from 'react';
import { Button } from '../Components/button';
import '../Components/hero.css';

const Hero = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='hero-container'>
      <img src='/hero.png' alt='hero' className='hero' />
      <h1>EMPOWERING THE NEXT GENERATION OF SOFTWARE ENGINEERS</h1>
      <div className='hero-btns'>
        <Button 
          to='/initiatives' 
          buttonStyle='btn--outline' 
          padding='1.2vh 4.5vh'
          fontSize='3vh'
          onClick={scrollToTop} // Added onClick handler
        >
          Learn More
        </Button>
      </div>
    </div>
  );
}

export default Hero;